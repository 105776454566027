import "./App.css";
import { useMemo } from "react";
import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import Minter from "./Minter";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import * as anchor from "@project-serum/anchor";
import { clusterApiUrl } from "@solana/web3.js";
import { WalletAdapterNetwork } from "@solana/wallet-adapter-base";
import {
  getPhantomWallet,
  getSolflareWallet,
  getSolletWallet,
  getMathWallet,
} from "@solana/wallet-adapter-wallets";

import {
  ConnectionProvider,
  WalletProvider,
} from "@solana/wallet-adapter-react";

import { WalletDialogProvider } from "@solana/wallet-adapter-material-ui";
import { ThemeProvider, createTheme } from "@material-ui/core";


const theme = createTheme({
  palette: {
    type: "dark",
  },
});

const candyMachineId = process.env.REACT_APP_CANDY_MACHINE_ID
  ? new anchor.web3.PublicKey(process.env.REACT_APP_CANDY_MACHINE_ID)
  : undefined;

const network = process.env.REACT_APP_SOLANA_NETWORK as WalletAdapterNetwork;

const rpcHost = process.env.REACT_APP_SOLANA_RPC_HOST!;
const connection = new anchor.web3.Connection(rpcHost);

const startDateSeed = parseInt(process.env.REACT_APP_CANDY_START_DATE!, 10);

const txTimeout = 30000; // milliseconds (confirm this works for your project)

const App = () => {
  const endpoint = useMemo(() => clusterApiUrl(network), []);

  const wallets = useMemo(
    () => [getPhantomWallet(), getSolflareWallet(), getSolletWallet(), getMathWallet()],
    []
  );

  function toggleMenu() {
    const menu = document.getElementById("mobileNavContainer")!;
    menu.classList.toggle("open-menu");
    console.log("pressed");
  }

  return (
    <div>
      <div id="mobileNavContainer" className="mobile-nav">
        <div className="mobile-nav-close-button" >
          <img src="/icons/close.svg" color="white" alt="" onClick={toggleMenu} />
        </div>
        <ul>
          <li>
            <a href="/#mint" onClick={toggleMenu}>
              Mint
            </a>
          </li>
          <li>
            <a href="/#story" onClick={toggleMenu}>
              Story
            </a>
          </li>
          <li>
            <a href="/#roadmap" onClick={toggleMenu}>
              Roadmap
            </a>
          </li>
          <li>
            <a href="/#team" onClick={toggleMenu}>
              Team
            </a>
          </li>
          <li>
            <a href="/#faq" onClick={toggleMenu}>
              FAQs
            </a>
          </li>
          <li>
            <div className="social-icons">
              <a href="https://twitter.com/astrofoxesnft" target="_blank"><img className="nav-social" src="/icons/twitter.svg" alt="" /></a>
              <a href="https://discord.gg/" target="_blank"><img className="nav-social" src="/icons/discord.svg" alt="" /></a>


            </div>
          </li>
        </ul>
      </div>
      <div className="mobile-menu-button" onClick={toggleMenu}>
        <img src="/icons/menu.svg" alt="" />
      </div>

      <div className="content-wrapper">
        <div className="main-banner">
          <div className="welcome-over"></div>
          <nav>
            <div className="nav-container">
              <a className="hide-800" href="/#mint">
                Mint
              </a>
              <a className="hide-800" href="/#story">
                Story
              </a>
              <a className="hide-800" href="/#roadmap">
                Roadmap
              </a>
              <a className="hide-800" href="/#team-section">
                Team
              </a>
              <a className="hide-800" href="/#faq">
                FAQ
              </a>
              <div className="social-icons hide-800">
                <a href="https://twitter.com/astrofoxesnft" target="_blank"><img className="nav-social" src="/icons/twitter.svg" alt="" /></a>
                <a href="https://discord.gg/" target="_blank"><img className="nav-social" src="/icons/discord.svg" alt="" /></a>
         

              </div>
            </div>
          </nav>
          <div className="welcome" id="mint">
            <div className="welcome-left">
              <h3 className="text-secondary-color">Welcome To</h3>
              <h1 className="pb-3">Astro Foxes</h1>
              <p className="text-secondary-color">
                A clever and witty pack of astro foxes storming into the solana ecosystem bringing wallet tracking, community, and alpha united by our love for foxes
                <br></br><br></br>
              </p>
            </div>

            <div>
              <ThemeProvider theme={theme}>
                <ConnectionProvider endpoint={endpoint}>
                  <WalletProvider wallets={wallets} autoConnect>
                    <WalletDialogProvider>

                      <Minter
                        candyMachineId={candyMachineId}

                        connection={connection}
                        startDate={startDateSeed}
                        txTimeout={txTimeout}
                        rpcHost={rpcHost}
                      />

                    </WalletDialogProvider>
                  </WalletProvider>
                </ConnectionProvider>
              </ThemeProvider>
            </div>
          </div>
          <div className="join-community"><a target="_blank" href="https://discord.gg/" rel="noreferrer">Join Astro Foxes
            <img className="nav-social" src="/icons/discord.svg" alt="" /></a></div>
        </div>


        <div id="story" className="container">
          <div className="story-left">
            <h1>Our Story</h1>
            <p>
              In the nft community, we were inspired by everyone working toward the same goal, sharing knowledge while discovering and exploring the ecosystem. From going through emotional swings from hitting god mints to losing solana from rug pulls, the community stays tough through the thick and thin, we support each other.
              <br></br><br></br>
              This is why we, the team here at Astro Foxes, set out to create  the most valuable, community-centered alpha groups with our advanced wallet tracking of influencers and knowledgeable nft traders, alpha, and strong community.<br></br><br></br>
            </p>
          </div>
          <img src="/img/nyx.png" alt=""></img>
        </div>


        <div id="benefits">
          <div className="about-title">BENEFITS</div>
          <div className="container-card">
            <div className="card firstbenefits">
              <h5>HOLDERS</h5>
              <ul>
                <li className="text-left">Wallet Tracking: Not your everyday wallet tracking. Potentially one of the most valuable resources in Solana NFTs, we track reputable influencers and knowledgeable nft traders and get their moves instantly in our discord (soon to be website) before they call out their plays to their own audience making the price pump.</li>
                <li className="text-left">Community Rug Fund: Rugs are highly prevalent in this market and are very damaging to your funds and goals. If you are rug-pulled, we want to offer refunds to protect holders of Astro Foxes.</li>
                <li className="text-left">Daily Digest: Sometimes break days are needed as you might want to take a step back from the nft space as it does get exhausting. We will be doing daily reports of events that happened with Solana nfts giving the TL:DR treatment to market sentiment, how projects reacted post mint, big sales, and just overall news.</li>
                <li className="text-left">Corrupted Foxes: Access to mint, second collection open to Astro Fox holders furthering the lore</li>
                <li className="text-left">Q&A w/ other projects: We want to reach out to other upcoming communities and get a good understanding of their mission and project. We want to use this discord as a stage for Devs and project founders to share why their project is worthwhile.</li>
                <li className="text-left">Alerts/Upcoming Mints: Today, there are many projects to keep eyes on, and it gets difficult to manage. We want to help manage it by sending out specified alerts and reminders through Discord and Phone notifications, so you don't miss out on any plays, mints, or staking events. To ensure we do not miss any big opportunities, we plan to hire more team members to post mint.</li>
                <li className="text-left">Project Analysis: We want to highlight prominent projects via social media metrics, member to supply ratio, and utility. The team will also do its best to secure whitelist spots to give to Astro Fox holders for upcoming collections we like or suggest.</li>
              </ul>
            </div>

          </div>
         
        </div>

        <div id="roadmap">
          <div className="container roadmap">
            <h1 style={{ padding: "0 0 24px 0", color: "#000", textAlign: 'center', fontSize: "44px" }}>ROADMAP</h1>
            <div>
              <VerticalTimeline>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{ background: '#B8E4F0 ', color: 'black' }}
                  contentArrowStyle={{ borderRight: '7px solid  #B8E4F0 ' }}
                  date=""
                  iconStyle={{ background: '#B8E4F0 ', color: 'black' }}
                //icon={<WorkIcon />}
                >
                  <h3 className="vertical-timeline-element-title">Phase 1</h3>
                  <ul>
                    <li>launch discord</li>
                    <li>partner with creators on twitter</li>
                    <li>partner with projects</li>
                    <li>preapprove for MagicEden listing</li>
                    <li>reveal wallet tracking tool & wallet tracking website mockup</li>
                    <li>close whitelist</li>
                    <li>mint day</li>
                  </ul>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{ background: '#B8E4F0 ', color: 'black' }}
                  contentArrowStyle={{ borderRight: '7px solid  #B8E4F0 ' }}
                  date=""
                  iconStyle={{ background: '#B8E4F0 ', color: 'black' }}
                //icon={<WorkIcon />}
                >
                  <h3 className="vertical-timeline-element-title">Phase 2</h3>
                  <ul>
                  <li>bring on new team members</li>
                  <li>roll out all functions of the discord</li>
                  <li>launch anti-rug fund</li>
                  <li>holder giveaways</li>
                  <li>Astro Fox merch</li>
                  </ul>
                </VerticalTimelineElement>
                <VerticalTimelineElement
                  className="vertical-timeline-element--work"
                  contentStyle={{ background: '#B8E4F0 ', color: 'black' }}
                  contentArrowStyle={{ borderRight: '7px solid  #B8E4F0 ' }}
                  date=""
                  iconStyle={{ background: '#B8E4F0 ', color: 'black' }}
                //icon={<WorkIcon />}
                >
                  <h3 className="vertical-timeline-element-title">Phase 3</h3>
                  <ul>
                  <li>partnered events with projects</li>
                  <li>launch wallet tracking site</li>
                  <li>Corrupted Foxes</li>
                  more to come!
                  </ul>
                </VerticalTimelineElement>
              </VerticalTimeline>
            </div>
          </div>
        </div>


        <div id="team-section">
          <h1 style={{ padding: "0 0 24px 0", color: "#000", textAlign: 'center', fontSize: "44px" }}>TEAM</h1>
          <div className="team">
            <Card className="pad-card team-member" variant="outlined" sx={{ maxWidth: 345 }}>
              <CardMedia
                component="img"
                height="300"
                image="/img/team1.png"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div" textAlign="center">
                  Founder
                </Typography>
                <Typography variant="body2" color="black" textAlign="center">
                Resu
                </Typography>
              </CardContent>
            </Card>
            <Card className="pad-card team-member" sx={{ maxWidth: 345 }}>
              <CardMedia
                component="img"
                height="300"
                image="/img/team2.png"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div" textAlign="center">
                Community Manager
                </Typography>
                <Typography variant="body2" color="black" textAlign="center">
                Cam!
                </Typography>
              </CardContent>
            </Card>
            <Card className="pad-card team-member" sx={{ maxWidth: 345 }}>
              <CardMedia
                component="img"
                height="300"
                image="/img/team3.png"
              />
              <CardContent>
                <Typography gutterBottom variant="h5" component="div" textAlign="center">
                Artist
                </Typography>
                <Typography variant="body2" color="black" textAlign="center">
                The New Tofu
                </Typography>
              </CardContent>
            </Card>
            <Card className="pad-card team-member" sx={{ maxWidth: 345 }}>
              <CardMedia
                component="img"
                height="300"
                image="/img/team4.png"
              />
              <CardContent >
                <Typography gutterBottom variant="h5" component="div" textAlign="center">
                Community Manager
                </Typography>
                <Typography variant="body2" color="black" textAlign="center">
                PureEclipse
                </Typography>
              </CardContent>
            </Card>

          </div>
        </div>



        <div id="faqcolor">
          <div id="faq">
            <div className="faq-component">
              <h1 style={{ padding: "0 0 24px 0", color: "#000", textAlign: 'center', fontSize: "44px" }}>FAQs</h1>
              <Accordion className="faq-item">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  <Typography>What are AstroFoxes?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                  An alpha discord centered around the community. Providing a Rug Relief fund and much of the Solana from artist royalties going to active/contributing members. This is supplemented with us, the team, providing tools such as a wallet tracker tracking well known influencers and their every move.
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="faq-item">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Will this be an NFT?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                  Yes, we will be releasing Astro Foxes as a Solana NFT
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="faq-item">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Will this be listed on secondary marketplaces?
</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                  Yes, we will be listed on Magic Eden and other that will be announced

                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="faq-item">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Where to find updates and more information?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                  Our discord and twitter!
                  </Typography>
                </AccordionDetails>
              </Accordion>
              <Accordion className="faq-item">
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2a-content"
                  id="panel2a-header"
                >
                  <Typography>Which wallet can I use?</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Typography>
                    Phantom, Solflare or Sollet.io
                  </Typography>
                </AccordionDetails>
              </Accordion>

            </div>
          </div>
        </div>



      </div>
      <footer className="footer">
        <div className="text-footer">
          <p>Astro Foxes</p>
          <p>©All Rights Reserved</p></div>
      </footer>
    </div>
  );
};

export default App;
